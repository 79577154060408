import React from "react"
import { Typography } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import Parameters from "../../helpers/parameters";

const SfGrid = styled(Grid)`
  margin: 2rem 0 2rem 0;
`

const PageTitle = () => {
  const data = useStaticQuery(graphql`
    query TitlePage {
      strapiParametre(optiontype: { eq: "accueil" }) {
        name
        desc
      }
    }
  `)
  return (
    <SfGrid container justify={"center"}>
      <Grid item sm={12}>
        <Typography variant={"h3"} component={"h1"} align={"center"}>
          {Parameters.getParametreName(data.strapiParametre)}
        </Typography>
        <br />
        <Typography variant={"body1"} component={"p"} align={"center"}>
          {Parameters.getParametreDesc(data.strapiParametre)}
        </Typography>
      </Grid>
    </SfGrid>
  )
}

export default PageTitle
