import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

interface pageContext {
  pageContext: any
}

const SfTypo = styled(Typography)`
  margin: 20px auto 20px auto;
`
const SfLink = styled(Link)`
  margin-right: 5% !important;
  text-decoration: none;
`

const Pagination = ({ pageContext }: pageContext) => {
  const currentPage = pageContext.index
  const totalPage = pageContext.pageCount
  const nextPage = currentPage + 1
  const previousPage =
    currentPage - 1 === 0 || currentPage - 1 === 1 ? "/" : currentPage - 1
  const firstPage = pageContext.first
  const nextPagePath = !pageContext.last ? "/" + nextPage.toString(10) : null
  return (
    <SfTypo
      variant={"caption"}
      align={"center"}
      component={"p"}
    >
      {!firstPage && <SfLink to={previousPage}>Précédent</SfLink>}
      {!firstPage && <SfLink to={previousPage}>{currentPage - 1}</SfLink>}
      <SfLink to={currentPage === 1 ? "/" : currentPage}>{currentPage}</SfLink>
      {totalPage - 1 >= currentPage + 1 && (
        <SfLink to={currentPage + 1}>{currentPage + 1}</SfLink>
      )}
      {totalPage - 1 >= currentPage + 2 && (
        <SfLink to={currentPage + 2}>...</SfLink>
      )}
      {!pageContext.last && <SfLink to={totalPage}>{totalPage}</SfLink>}
      {!pageContext.last && <SfLink to={nextPagePath}>Suivant</SfLink>}
    </SfTypo>
  )
}

export default Pagination
