import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle"
import { Grid } from "@material-ui/core"
import RealisationCard from "../components/CardRealisation"
import Pagination from "../components/Pagination"
import { ButtonToutesNosReals } from "../components/ButtonToutesNosReals"

const IndexPage = ({ pageContext }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <PageTitle />
      <Grid container spacing={2}>
          <ButtonToutesNosReals />
        {pageContext.group.map(({ node }) => (
          <Grid item md={4} sm={6}>
            <RealisationCard details={node} key={node.id} />
          </Grid>
        ))}
      </Grid>
      <Pagination pageContext={pageContext} />
      <ButtonToutesNosReals />
    </Layout>
  )
}

export default IndexPage
