import { Button } from "@material-ui/core"
import React from "react"

import styled from "styled-components"

const CustomBtn = styled(Button)`
  background-color: #ebaf28;
  color:#ffffff;
  &:hover {
    background-color: #2cade7;
  }
`

export const ButtonToutesNosReals = () => (
  <CustomBtn
    fullWidth
    size={"large"}
    variant={"contained"}
    href={"trouvez-une-realisation"}
  >
    Trouvez une réalisation
  </CustomBtn>
)
